import React from "react";

const SuperAgentStats = ({ stats }) => {
    console.log("conflict", stats.Conflict)
  const {
    valid,
    pending,
    conflict,
    stolen,
    expired,
    approved,
    cancelled,
    new: newCount,
    renewal,
  } = stats;

  const totalVehicles = stats.Valid + stats.Conflict + stats.Stolen;
  const totalRequests = stats.Pending + stats.Expired + stats.Approved + stats.Cancelled;

  const statsData = [
    {
      label: "Total Vehicles",
      value: totalVehicles,
      color: "bg-blue-600",
    },
    {
      label: "Total Requests",
      value: totalRequests,
      color: "bg-green-600",
    },
  ];

  const additionalStats = [
    // { label: "Valid", value: stats.Valid, color: "bg-indigo-500" },
    // { label: "Conflict", value: stats.Conflict, color: "bg-red-500" },
    // { label: "Stolen", value: stats.Stolen, color: "bg-red-500" },
    
    { label: "Pending", value: stats.Pending, color: "bg-yellow-500" },
    { label: "Approved", value: stats.Approved, color: "bg-teal-500" },
    { label: "Expired", value: stats.Expired, color: "bg-red-500" },
    { label: "Cancelled", value: stats.Cancelled, color: "bg-pink-500" },
    // { label: "New", value: stats.New, color: "bg-blue-400" },
    // { label: "Renewal", value: stats.Renewal, color: "bg-green-400" },
  ];

  return (
    <div className="container mx-auto">
        <div
            className={`flex flex-col items-center justify-center p-4 rounded-lg shadow-md ${statsData[0].color} text-white`}
          >
            <h2 className="text-lg font-semibold">Total Request</h2>
            <p className="text-2xl font-bold">{statsData[0].value}</p>
        </div>
      {/* <h1 className="text-2xl font-bold text-center mb-6">Statistics Overview</h1> */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        

        {/* Group 1: Total Vehicles and Total Requests */}
        {/* {statsData.map((stat, index) => (
          <div
            key={index}
            className={`flex flex-col items-center justify-center p-4 rounded-lg shadow-md ${stat.color} text-white`}
          >
            <h2 className="text-lg font-semibold">{stat.label}</h2>
            <p className="text-2xl font-bold">{stat.value}</p>
          </div>
        ))} */}

        {/* Group 2: Additional Stats */}
        {additionalStats.map((stat, index) => (
          <div
            key={index}
            className={`flex flex-col items-center justify-center p-4 shadow-2xl ${stat.color} text-white`}
          >
            <h2 className="text-lg font-semibold">{stat.label}</h2>
            <p className="text-2xl font-bold">{stat.value}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SuperAgentStats;
