import React, { useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import Swal from "sweetalert2";
import { base, get_certificate } from "../constants/urls";
import { useAuth } from "../store/Auth";
import { formatDateNew } from "../utils/date_format";
import Loader from "./Loader";
import image from "./images/cowcertificate.png";
import signature from "./images/AcCMR_Signature.png";
import { RiPrinterLine } from "react-icons/ri";
import QRCode from "react-google-qrcode";

export default function ChangeOfOwnershipCertificate() {
  const { ref } = useParams();
  const [data, setData] = useState({});
  const getAuth = useAuth((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

//   const errorFunc = () => {
//     Swal.fire({
//       title: "Error!",
//       text: "Unable to load certificate",
//       icon: "error",
//       confirmButtonText: "Ok",
//       confirmButtonColor: "#1C89DE",
//     }).then(() => {
//       navigate(-1);
//     });
//   };

//   const fetchRequest = async () => {
//     const token = getAuth.token;
//     const url = `${base}${get_certificate}/cmrinfo/${ref}`;
//     try {
//       setLoading(true);
//       const response = await fetch(url, {
//         method: "GET",
//         headers: {
//           "Content-Type": "application/json",
//           // Authorization: "Bearer " + token,
//         },
//       });
//       const { data } = await response.json();
//       console.log({ data });
//       setData(data);
//       setLoading(false);
//     } catch (error) {
//       errorFunc();
//       console.log({ error });
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     fetchRequest();
//   }, []);

//   if (loading) {
//     return <Loader />;
//   } else if (!loading && !data.owner_id) {
//     return <div></div>;
//   }

  const validityUrl = `${process.env.REACT_APP_URL + "/verify"}/${ref}`;

  return (
    <>
      {/* <div style={{backgroundImage: `url(${image})`}} className="p-2 bg-contain bg-no-repeat bg-center w-5/6 h-full bg-scroll"> */}
      <div className="bg-white overflow-x-scroll certificate">
        <div className="h-full mx-auto m-5">
          <div className="relative flex items-center">
            <div className="w-full">
              <div className="z-0 relative w-[1024px] print:w-[700px] print:h-full mx-auto">
                <img src={image} alt="certificate" className="" />
                <div className="absolute top-1/4 left-36 tracking-tighter">
                    <h1 className="text-3xl font-bold">MOTOR VEHICLE CHANGE OF OWNERSHIP CERTIFICATE</h1>
                </div>
                <div className="absolute top-1/4 left-36 print:left-24 mt-10 md:mt-20 w-3/4 h-3/4">
                  <div className="flex justify-between items-center mt-2 md:mt-5 mb-2 md:mb-4">
                    <h4 className="flex space-x-3">
                      <p className="text-green-700 font-bold">CMRIS No.:</p>
                      <span className="font-extrabold">
                        {/* {" " + data.certificate_number} */} 1234
                      </span>
                    </h4>
                    <h4 className="font-bold flex space-x-3">
                      <p className="text-green-600">Date:</p>
                      <span className="font-extrabold">
                        {/* {formatDateNew(data.cmr_registration_date)} */}02-05-2023
                      </span>
                    </h4>
                  </div>

                    <div className="mb-2 text-lg">
                        <p className="font-semibold">Previous Owner</p>
                    </div>
                  <table className="mb-5 mt-2 text-lg table-auto">
                    <tbody>
                      <tr className="">
                        <td className="font-normal">Name: </td>
                        <td className="font-extrabold pl-6">
                            {/* {data.name} */}
                        </td>
                      </tr>
                      <tr className="">
                        <td className="font-normal">Address: </td>
                        <td className="font-extrabold pl-6">
                            {/* {data.address} */}
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <div className="w-full pl-8">
                    <div>
                      <h5 className="mb-2 mt-4 print:mt-5 text-xl font-semibold">
                        The motor vehicle particulars are as follows:
                      </h5>

                      <div className=" mb-4">
                        <table className="text-left text-black text-lg print:text-base">
                          <tbody>
                            <tr className="">
                              <td className="pr-6 py-1.5 print:py-0 font-semibold">
                                Vehicle Registration No.:
                              </td>
                              <td className="pl-4 lg:pl-12 py-1.5 print:py-0 font-extrabold">
                                {/* {data.license_plate} */}
                              </td>
                            </tr>
                            <tr className="">
                              <td className="pr-6 py-1.5 print:py-0 font-semibold">
                                VIN/Chassis No.:
                              </td>
                              <td className="pl-4 lg:pl-12 py-1.5 print:py-0 font-extrabold">
                                {/* {data.chassis_number} */}
                              </td>
                            </tr>
                            <tr className="">
                              <td className="pr-6 py-1.5 print:py-0 font-semibold">
                                Engine No.:
                              </td>
                              <td className="pl-4 lg:pl-12 py-1.5 print:py-0 font-extrabold">
                                {/* {data.engine_number} */}
                              </td>
                            </tr>
                            <tr className="">
                              <td className="pr-6 py-1.5 print:py-0 font-semibold">
                                Vehicle Make:
                              </td>
                              <td className="pl-4 lg:pl-12 py-1.5 print:py-0 font-extrabold">
                                {/* {data.vehicle_make} */}
                              </td>
                            </tr>

                            <tr className="">
                              <td className="pr-6 py-1.5 print:py-0 font-semibold">
                                Vehicle Model:
                              </td>
                              <td className="pl-4 lg:pl-12 py-1.5 print:py-0 font-extrabold">
                                {/* {data.vehicle_model} */}
                              </td>
                            </tr>

                            <tr className="">
                              <td className="pr-6 py-1.5 print:py-0 font-semibold">
                                Vehicle Type:
                              </td>
                              <td className="pl-4 lg:pl-12 py-1.5 print:py-0 font-extrabold">
                                {/* {data.vehicle_type} */}
                              </td>
                            </tr>

                            <tr className="">
                              <td className="pr-6 py-1.5 print:py-0 font-semibold">
                                Year of Manufacture:
                              </td>
                              <td className="pl-4 lg:pl-12 py-1.5 print:py-0 font-extrabold">
                                {/* {data.year} */}
                              </td>
                            </tr>

                            <tr className="">
                              <td className="pr-6 py-1.5 print:py-0 font-semibold">
                                Vehicle Colour:
                              </td>
                              <td className="pl-4 lg:pl-12 py-1.5 md:py-1.5 print:py-0 font-extrabold">
                                {/* {data.color} */}
                              </td>
                            </tr>

                            <tr className="">
                              <td className="pr-6 py-1.5 print:py-0 font-semibold">
                                Motor Vehicle Licencing Authority:
                              </td>
                              <td className="pl-4 lg:pl-12 py-1.5 print:py-0 font-extrabold">
                                {/* {data.vehicle_licensing_authority} */}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                    <div className="mb-2 text-lg">
                        <p className="font-semibold">New Owner</p>
                    </div>
                  <table className="mb-5 mt-1 text-lg table-auto">
                    <tbody>
                      <tr className="">
                        <td className="font-normal">Name: </td>
                        <td className="font-extrabold pl-6">
                            {/* {data.name} */}
                        </td>
                      </tr>
                      <tr className="">
                        <td className="font-normal">Address: </td>
                        <td className="font-extrabold pl-6">
                            {/* {data.address} */}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="my-3 absolute bottom-[170px] print:bottom-[100px] print:-ml-12 md:bottom-[190px] w-80">
                    <div className="">
                      <img
                        src={signature}
                        alt="signature"
                        className="w-32 h-auto bottom-8 absolute ml-20"
                      />
                      <h2 className="text-base tracking-tight font-semibold text-center md:text-lg print:text-md">
                        <span className="font-extrabold">
                          ACP Adebayo Adedayo{" "}
                        </span>
                        <br />
                        Asst. Commissioner of Police (CMR)
                      </h2>
                    </div>
                  </div>
                  <div className="absolute left-[615px] top-[810px] md:top-[772px] print:top-[520px] print:left-[420px]">
                    <QRCode data={validityUrl} size={100} />
                  </div>
                </div>
              </div>
              <div className="relative flex justify-center space-x-3 z-50">
                <Link
                  className="print:hidden flex text-md justify-center"
                  to="/requests"
                >
                  <button className="flex cursor print:hidden items-center justify-center border px-4 py-1 text-white font-semibold border-blue-500 rounded bg-blue-500">
                    Back
                  </button>
                </Link>
                <button
                  className="flex print:hidden items-center justify-center space-x-1 border px-3 py-1 rounded border-red-500"
                  onClick={() => {
                    window.print();
                  }}
                >
                  <RiPrinterLine />
                  <p>Print</p>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
