import React, { useEffect, useState } from "react";
import { useAuth } from "../store/Auth";
import {base, t1_current_balance, t1_total_earnings, t2_total_generated, tier1stats} from "../constants/urls";
import { number_format } from "../utils/number_format";
import SuperAgentStats from "../components/SuperAgentStats";

export default function Dashboard() {
  const [currentBalance, setCurrentBalance] =useState(0)
  const [totalGenerated, setTotalGenerated] = useState(0)
  const getAuth = useAuth((state) => state.auth);
  const [stats, setStats] = useState({
    valid: 0,
    pending: 0,
    conflict: 0,
    stolen: 0,
    expired: 0,
    approved: 0,
    cancelled: 0,
    new: 0,
    renewal: 0,
  })

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const fetchStats = async (startDate, endDate) => {
    let token = getAuth.token;
    // alert(`${base+tier1stats}?date_from=${startDate}&date_to=${endDate}`)
    let url=`${base+tier1stats}?date_from=${startDate}&date_to=${endDate}`

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      const data = await response.json();
      // console.log(data.data)
      setStats(data.data);
    } catch (error) {
      console.error("Error fetching stats:", error);
    }
  };

  const handleFilter = () => {
    fetchStats(startDate, endDate);
  };

  const getCurrentBalance = () => {
    const url = base + t1_current_balance;
    let token = getAuth.token;
    fetch(url, {
      method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
    }).then((response)=>{
      return response.json();
    }).then((jsonResp)=>{
      console.log("Current Balance: "+jsonResp.data)
      setCurrentBalance(jsonResp.data)
    })
  }

  const getEarnings = () => {
    const url = base + t1_total_earnings;
    let token = getAuth.token;
    fetch(url, {
      method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
    }).then((response)=>{
      return response.json();
    }).then((jsonResp)=>{
      console.log("Total Generated: "+jsonResp.data)
      setTotalGenerated(jsonResp.data)
    })
  }
  useEffect(() => {
    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().split('T')[0];
    fetchStats('2023-01-01', formattedDate)
    // getCurrentBalance();
    // getEarnings();
  },[])

  return (
    <div className="bg-gray-100">
      <div className="text-2xl pt-4 pb-4 bg-blue-200 font-bold text-center">
        CMR ASSISTANT PORTAL
      </div>
      <div className="flex justify-center items-center mb-6 mt-3">
        {/* Date Range Inputs */}
        <input
          type="date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          className="border rounded-lg p-2 mx-2"
          placeholder="Start Date"
        />
        <input
          type="date"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
          className="border rounded-lg p-2 mx-2"
          placeholder="End Date"
        />
        <button
          className="bg-blue-500 text-white rounded-lg px-4 py-2 ml-2"
          onClick={handleFilter}
        >
          Apply
        </button>
      </div>
      <SuperAgentStats stats={stats} />
      {/* <div className="flex mx-6 mt-4">
        <div className="flex-1 w-full bg-blue-400 rounded-lg h-32">
          <div className="text-gray-100 text-center font-bold text-xl mt-3">Total Collection</div>
          <div className="text-gray-100 text-center font-bold text-4xl mt-3">N{number_format(totalGenerated, 2)}</div>
        </div>
        
        <div className="flex-1 w-full"></div>

        <div className="flex-1 w-full bg-yellow-900 text-gray-10 rounded-lg h-32">
          <div className="text-gray-100 text-center font-bold text-xl mt-3">Total Received</div>
          <div className="text-gray-100 text-center font-bold text-4xl mt-3">N{number_format(totalGenerated-currentBalance, 2)}</div>
        </div>
        
        <div className="flex-1 w-full"></div>
        
        <div className="flex-1 w-full bg-green-400 rounded-lg h-32">
          <div className="text-gray-100 text-center font-bold text-xl mt-3">Current Balance</div>
          <div className="text-gray-100 text-center font-bold text-4xl mt-3">N{number_format(currentBalance, 2)}</div>
        </div>
        
      </div> */}

      {/* <table width="97%" className="table mx-6 mt-3 border-collapse border border-slate-400">
        <thead className="bg-gray-200 text-gray-700 text-left">
          <tr>
            <th className="border border-slate-300">S/No.</th>
            <th className="border border-slate-300">Location</th>
            <th className="border border-slate-300">Number of Requests</th>
            <th className="border border-slate-300">Amount Earned</th>
          </tr>
        </thead>
        <tbody className="text-left">
        <tr>
            <td className="border border-slate-300">1</td>
            <td className="border border-slate-300">MLA Ikeja</td>
            <td className="border border-slate-300">5000</td>
            <td className="border border-slate-300">2,500,000.00</td>
          </tr>

          <tr>
            <td className="border border-slate-300">2</td>
            <td className="border border-slate-300">MLA Shomolu</td>
            <td className="border border-slate-300">3000</td>
            <td className="border border-slate-300">1,500,000.00</td>
          </tr>

          <tr>
            <td className="border border-slate-300">3</td>
            <td className="border border-slate-300">MLA Ojota</td>
            <td className="border border-slate-300">3000</td>
            <td className="border border-slate-300">1,500,000.00</td>
          </tr>
        </tbody>
      </table> */}
    </div>
  );
}
